import React from 'react';
import { changeAdminPassword } from 'services/api/adminsService';
import { closeModal, setIsDataLoadingParameter } from 'services/store/reducers/modalReducer';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { Admin, ESnackbarStyle, ModalState } from 'shared/types';
import { Modal, Button, Input } from 'antd';
import { useFormik } from 'formik';
import * as Styled from './styles';
import { getFieldError } from 'utils/error-utils';
import { validation } from 'services/validation';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { openNotification } from 'utils/notification-utils';

type FormValues = {
  password: string;
  confirmPassword: string;
};

type Props = {
  adminId: number;
  fetchAdmins?: () => Promise<void>;
};

const ChangePasswordModal: React.FC<Props> = ({
  adminId,
  fetchAdmins = (): Promise<void> => {
    return new Promise(() => {});
  }
}): JSX.Element => {
  const initialValues: FormValues = {
    password: '',
    confirmPassword: ''
  };

  const dispatch = useAppDispatch();
  const { isModalOpened, isModalDataLoading } = useAppSelector((state): ModalState => state.modal);
  const user = useAppSelector((state): Admin | null => state.auth.user);

  const formik = useFormik({
    onSubmit: async (values): Promise<void> => {
      dispatch(setIsDataLoadingParameter(true));
      try {
        await changeAdminPassword(adminId, values.password);
        await fetchAdmins();
      } catch (e) {
        openNotification(ESnackbarStyle.ERROR, e.message);
      } finally {
        dispatch(setIsDataLoadingParameter(false));
        dispatch(closeModal());
      }
    },
    initialValues,
    validationSchema: validation.CHANGE_ADMIN_PASSWORD
  });

  const handleCancel = (): void => {
    dispatch(closeModal());
  };

  return (
    <Modal
      open={isModalOpened}
      title={adminId === user?.id ? 'Change password' : 'Change admin password'}
      onCancel={handleCancel}
      footer={[
        <Button
          key='save'
          type={'primary'}
          onClick={(): void => formik.handleSubmit()}
          disabled={isModalDataLoading}
        >
          Save
        </Button>
      ]}
    >
      <form onSubmit={formik.handleSubmit}>
        <Styled.InputContainer>
          <Input.Password
            name={'password'}
            value={formik.values.password}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            placeholder={'Password'}
            iconRender={(visible): JSX.Element =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
            disabled={isModalDataLoading}
          />
          {getFieldError(formik, 'password')}
        </Styled.InputContainer>
        <Styled.InputContainer>
          <Input.Password
            name={'confirmPassword'}
            value={formik.values.confirmPassword}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            placeholder={'Confirm password'}
            iconRender={(visible): JSX.Element =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
            disabled={isModalDataLoading}
          />
          {getFieldError(formik, 'confirmPassword')}
        </Styled.InputContainer>
      </form>
    </Modal>
  );
};

export default ChangePasswordModal;
