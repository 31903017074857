import React from 'react';
import { Modal, Button, Empty, Tooltip } from 'antd';
import { MessageOutlined, LineChartOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { closeModal } from 'services/store/reducers/modalReducer';
import { SessionVrUser, AnswerReports, ProgressReports, ChatReports } from 'shared/types';
import { UserField } from './components';
import Text from 'antd/lib/typography/Text';
import {
  downloadAnswerReports,
  downloadProgressReports,
  downloadChatReports
} from 'services/api/sessionsService';

type Props = {
  usersList: SessionVrUser[];
  sessionId?: string;
  internalId?: string;
  answerReports?: AnswerReports[];
  progressReports?: ProgressReports[];
  chatReports?: ChatReports[];
};

const AttendeesModal: React.FC<Props> = ({
  usersList,
  sessionId,
  internalId,
  answerReports,
  progressReports,
  chatReports
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const isModalOpened = useAppSelector((state): boolean => state.modal.isModalOpened);

  const handleCancel = (): void => {
    dispatch(closeModal());
  };

  const showAnswers = !!answerReports && !!answerReports.length;
  const showProgress = !!progressReports && !!progressReports.length;
  const showChatLogs = !!chatReports && !!chatReports.length;
  const showDowloadReports = (showAnswers || showProgress || showChatLogs) && sessionId;

  return (
    <Modal
      open={isModalOpened}
      title='Session details'
      onCancel={handleCancel}
      footer={[
        <Button key='cancel' type={'primary'} onClick={handleCancel}>
          Close
        </Button>
      ]}
      width={'1000px'}
    >
      {!!internalId && !!sessionId ? (
        <Text strong>
          <p>{`Student Session Id: ${internalId}`}</p>
          <p>{`System Session Id: ${sessionId}`}</p>
        </Text>
      ) : (
        ''
      )}
      {showDowloadReports && (
        <div>
          <Text strong>Download reports</Text>
          {showAnswers && (
            <Tooltip title='Answers'>
              <Button
                size='small'
                icon={<MessageOutlined />}
                style={{ marginLeft: 8 }}
                onClick={() => downloadAnswerReports(sessionId, answerReports)}
              />
            </Tooltip>
          )}
          {showProgress && (
            <Tooltip title='Progress'>
              <Button
                size='small'
                icon={<LineChartOutlined />}
                style={{ marginLeft: 8 }}
                onClick={() => downloadProgressReports(sessionId, progressReports)}
              />
            </Tooltip>
          )}
          {showChatLogs && (
            <Tooltip title='What users said'>
              <Button
                size='small'
                icon={<PlayCircleOutlined />}
                style={{ marginLeft: 8 }}
                onClick={() => downloadChatReports(sessionId, chatReports)}
              />
            </Tooltip>
          )}
        </div>
      )}
      {!!usersList.length ? (
        usersList.map((user): JSX.Element => <UserField user={user} key={user.id} />)
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description='No users were found for this session'
        />
      )}
    </Modal>
  );
};

export default AttendeesModal;
