import React, { useState, useEffect } from 'react';
import * as Styles from './styles';
import { Button, Input, Modal, Select } from 'antd';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { closeModal } from 'services/store/reducers/modalReducer';
import {
  ModalState,
  InputEvent,
  TableVrUser,
  School,
  VrUser,
  Admin,
  EAdminRoles
} from 'shared/types';
import { getIn, useFormik } from 'formik';
import { validation } from 'services/validation';
import { getFieldError } from 'utils/error-utils';
import { addNewUser, updateUserItem } from '../../../../services/store/reducers/usersReducer';
import _ from 'lodash';
import { ErrorMessage } from '../../../styles';

type Props = {
  user?: TableVrUser;
  schools: School[];
  vrUsers: VrUser[];
  fetchVrUsers: () => void;
};

const UserActionFormModal: React.FC<Props> = ({
  user,
  fetchVrUsers,
  schools,
  vrUsers
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector((state): Admin | null => state.auth.user);
  const { isModalOpened, isModalDataLoading } = useAppSelector((state): ModalState => state.modal);
  const [domain, setDomain] = useState(
    !!user && user.Email ? '@' + user?.Email?.split('@')?.[1] : ''
  );
  const [initVreduNumber] = useState(
    user?.Code ? user.Code : generateId(vrUsers, user || { ID: '', Code: '', OrganizationId: '' })
  );
  const domains = _.uniq(
    schools
      .flatMap((school: School) => {
        const domains = typeof school.Domain === 'string' ? [school.Domain] : school.Domain;
        return domains.map((domain) => `${domain}|${school.id}`);
      })
      .filter(Boolean)
      .map((domain) => (/^@/.test(domain) ? domain : `@${domain}`))
  );

  const handleCancel = (): void => {
    dispatch(closeModal());
  };

  useEffect(() => {
    if (!domain && currentUser?.role === EAdminRoles.SCHOOL_ADMIN) {
      const { domain } = currentUser.school;
      setDomain(/^@/.test(domain) ? domain : `@${domain}`);
    }
  }, [currentUser, domain]);

  const initialValues: TableVrUser = {
    firebaseId: user?.firebaseId || '',
    FirstName: user?.FirstName || '',
    LastName: user?.LastName || '',
    emailName: user?.Email?.split('@')?.[0] || '',
    Email: user?.Email || '',
    OrganizationId: user?.OrganizationId || '',
    ID: String(user?.ID) || '',
    key: String(user?.ID) || '',
    Code: String(initVreduNumber)
  };

  const formik = useFormik({
    onSubmit: async (values): Promise<void> => {
      if (
        !isValidNewId(vrUsers, {
          Code: getIn(formik.values, 'Code'),
          OrganizationId: getIn(formik.values, 'OrganizationId'),
          ID: getIn(formik.values, 'ID')
        })
      ) {
        return;
      }
      const newData = _.omit(values, ['key', 'firebaseId']);
      await dispatch(
        !!user && user.ID !== undefined
          ? updateUserItem({ userId: user.ID, values: newData })
          : addNewUser({
              values: {
                ...newData,
                Code: newData.Code
              }
            })
      );
      dispatch(closeModal());
      fetchVrUsers();
    },
    initialValues,
    validationSchema: !!user ? validation.UPDATE_VR_USER : validation.CREATE_VR_USER,
    enableReinitialize: true
  });

  const handleIdFieldChange = (event: InputEvent): void => {
    const { value, name } = event.target;
    if (name === 'emailName') {
      formik.setFieldValue('Email', value + domain);
    }
    formik.setFieldValue(name, value);
  };

  useEffect(() => {
    if (!domain && currentUser?.role === EAdminRoles.SCHOOL_ADMIN) {
      const { domain, id } = currentUser.school;
      setDomain(/^@/.test(domain) ? domain : `@${domain}`);
      formik.setFieldValue('OrganizationId', id);
    }
  }, [currentUser, domain, formik]);

  return (
    <Modal
      open={isModalOpened}
      title={!!user ? 'Edit User' : 'Add New User'}
      onCancel={handleCancel}
      footer={[
        <Button
          key='save'
          type={'primary'}
          onClick={(): void => formik.handleSubmit()}
          disabled={isModalDataLoading}
        >
          Save
        </Button>
      ]}
    >
      <form onSubmit={formik.handleSubmit}>
        <Styles.InputContainer key={'FirstName'}>
          <Input
            name={'FirstName'}
            value={getIn(formik.values, 'FirstName')}
            onBlur={formik.handleBlur}
            onChange={handleIdFieldChange}
            placeholder={'First name'}
            disabled={isModalDataLoading}
            allowClear
          />
          {getFieldError(formik, 'FirstName')}
        </Styles.InputContainer>
        <Styles.InputContainer key={'LastName'}>
          <Input
            name={'LastName'}
            value={getIn(formik.values, 'LastName')}
            onBlur={formik.handleBlur}
            onChange={handleIdFieldChange}
            placeholder={'Last name'}
            disabled={isModalDataLoading}
            allowClear
          />
          {getFieldError(formik, 'LastName')}
        </Styles.InputContainer>
        <Styles.InputContainer key={'emailName'}>
          <Input
            name={'emailName'}
            value={getIn(formik.values, 'emailName')}
            onBlur={formik.handleBlur}
            onChange={handleIdFieldChange}
            placeholder={'Email'}
            disabled={isModalDataLoading}
            allowClear
            style={{ width: '35%' }}
          />
          <Select
            style={{ marginLeft: '23px', width: '60%' }}
            onChange={(value) => {
              const [domain, OrganizationId] = value.split('|');
              setDomain(domain);
              formik.setFieldValue('Email', getIn(formik.values, 'emailName') + domain);
              formik.setFieldValue('OrganizationId', OrganizationId);
            }}
            placeholder={'School domain'}
            disabled={isModalDataLoading || currentUser?.role === EAdminRoles.SCHOOL_ADMIN}
            value={domain}
          >
            <Select.Option value={''}>{'Select a domain'}</Select.Option>
            {domains.map((domain: string) => {
              return (
                <Select.Option key={domain} value={domain}>
                  {domain.split('|')[0]}
                </Select.Option>
              );
            })}
          </Select>
          {getFieldError(formik, 'Email')}
        </Styles.InputContainer>

        <Styles.InputContainer key={'Code'}>
          <Input
            name={'Code'}
            value={getIn(formik.values, 'Code')}
            onBlur={formik.handleBlur}
            onChange={handleIdFieldChange}
            placeholder={'CurioXR Number'}
            disabled={isModalDataLoading}
            allowClear
          />
          {getFieldError(formik, 'Code')}
          {!isValidNewId(vrUsers, {
            Code: getIn(formik.values, 'Code'),
            OrganizationId: getIn(formik.values, 'OrganizationId'),
            ID: getIn(formik.values, 'ID')
          }) && <ErrorMessage>CurioXR number must be unique!</ErrorMessage>}
        </Styles.InputContainer>
      </form>
    </Modal>
  );
};

function isValidNewId(
  vrUsers: { ID: string; Code: string; OrganizationId: string }[],
  user: { ID: string; Code: string; OrganizationId: string }
): boolean {
  const set = vrUsers.filter(
    (users) =>
      users.Code === user.Code &&
      users.OrganizationId === user.OrganizationId &&
      users.ID !== user.ID
  );
  return !set.length;
}

function generateId(
  vrUsers: { ID: string; Code: string; OrganizationId: string }[],
  user: { ID: string; Code: string; OrganizationId: string }
): string {
  let newId = '';
  let searching = true;
  while (searching) {
    newId = String(1000 + Math.ceil(Math.random() * 8999));

    if (isValidNewId(vrUsers, user)) {
      searching = false;
    }
  }
  return newId;
}

export default UserActionFormModal;
