import React from 'react';
import { Modal } from 'antd';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { closeModal } from 'services/store/reducers/modalReducer';
import { ModalState } from 'shared/types';

type Props = {
  title: string;
  confirmAction: () => void;
};

const ConfirmModal: React.FC<Props> = ({ title, confirmAction }): JSX.Element => {
  const dispatch = useAppDispatch();
  const { isModalOpened, isModalDataLoading } = useAppSelector((state): ModalState => state.modal);

  const handleCancel = (): void => {
    dispatch(closeModal());
  };

  return (
    <Modal
      open={isModalOpened}
      title='Confirm'
      onCancel={handleCancel}
      onOk={confirmAction}
      okButtonProps={{ disabled: isModalDataLoading }}
      cancelButtonProps={{ disabled: isModalDataLoading }}
    >
      {title}
    </Modal>
  );
};

export default ConfirmModal;
