import React from 'react';
import { Button, Modal } from 'antd';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { closeModal } from 'services/store/reducers/modalReducer';
import { ModalState } from 'shared/types';
import ReactPlayer from 'react-player';
import { Text } from './styles';

type Props = {
  streamUrl: string;
};

const StreamModal: React.FC<Props> = ({ streamUrl }): JSX.Element => {
  const dispatch = useAppDispatch();
  const { isModalOpened, isModalDataLoading } = useAppSelector((state): ModalState => state.modal);

  const handleCancel = (): void => {
    dispatch(closeModal());
  };

  return (
    <Modal
      open={isModalOpened}
      title="Stream"
      onCancel={handleCancel}
      footer={[
        <Button key="close" type="primary" onClick={handleCancel} disabled={isModalDataLoading}>
          Close
        </Button>
      ]}
    >
      <ReactPlayer 
        url={streamUrl} 
        playing={true} 
        controls={true} 
        width="100%" 
        height="100%" 
      />
      <Text>Low resolution recordings ensure smooth performance in VR</Text>
    </Modal>
  );
};

export default StreamModal;
