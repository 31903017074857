import { DEVICE } from 'shared/constants/deviceSizes';
import styled from 'styled-components';

export const ErrorMessage = styled.div`
  margin-top: 3px;
  color: #de3618;
  font-size: 12px;
`;

export const PaginationContainer = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: center;
`;

export const TableContainer = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    !['isEmpty', 'withClickableRow', 'withHover', 'withTopMargin'].includes(prop as string),
})<{
  isEmpty: boolean;
  withClickableRow?: boolean;
  withHover?: boolean;
  withTopMargin?: boolean;
}>`
  margin-top: ${({ withTopMargin }) => (withTopMargin ? '25px' : '0')};

  & tbody tr {
    cursor: ${({ withClickableRow }) => (withClickableRow ? 'pointer' : 'auto')};
  }

  & tbody tr td:last-child div {
    @media (min-width: 769px) {
      opacity: ${({ withHover, isEmpty }) => (!withHover || isEmpty ? 1 : 0)};
      transition: all 0.1s;
    }
  }

  & tbody tr:hover {
    & td:last-child div {
      opacity: 1;
    }
  }
`;


export const AddButtonContainer = styled.div`
  & button {
    width: min-content;
  }
`;

export const UploadWrapper = styled.div`
  width: 70%;
  max-width: 740px;
  margin: 0 auto;
`;

export const UploadContainer = styled.div`
  text-align: center;
  padding: 30px;
  background: #eee;
  width: 94.5%;
  max-width: 700px;
  margin: 0 auto;
`;

export const InputContainer = styled.div`
  width: 220px;

  & input {
    cursor: initial;
  }
`;

export const SelectContainer = styled.div`
  width: 280px;
`;

export const SearchContainer = styled.div`
  width: 300px;
  margin-left: 15px;
`;

export const TableWrapper = styled.div`
  @media ${DEVICE.tablet} {
    .ant-table-tbody > tr > td > span {
      display: flex;

      &:before {
        content: attr(data-label);
        font-weight: bold;
        width: 120px;
        min-width: 120px;
      }
    }
  }
`;
