import styled from 'styled-components';

export const IframeContainer = styled.div`
  height: 50vh;
`;

export const StreamIframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: 0;
`;

export const Text = styled.div<{ withTopMargin?: boolean }>`
  font-size: 12px;
  color: #808080;
  margin-top: 10px;
`;
