import React, { ReactNode } from 'react';
import * as Styles from './styles';
import { Button, Checkbox, Input, Modal } from 'antd';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { closeModal } from 'services/store/reducers/modalReducer';
import { FormValuesUpdateSchool, ModalState, TableSchool } from 'shared/types';
import { getIn, useFormik } from 'formik';
import { validation } from 'services/validation';
import { getFieldError } from 'utils/error-utils';
import { addNewSchool, updateSchoolItem } from 'services/store/reducers/schoolsReducer';
import { FIELDS } from './constants';

type Props = {
  school?: TableSchool;
};

const SchoolActionFormModal: React.FC<Props> = ({ school }): JSX.Element => {
  const dispatch = useAppDispatch();
  const { isModalOpened, isModalDataLoading } = useAppSelector((state): ModalState => state.modal);

  const handleCancel = (): void => {
    dispatch(closeModal());
  };

  const initialValues: FormValuesUpdateSchool = {
    Name: school?.Name || '',
    userIdStart: school?.userIdStart || '',
    userIdEnd: school?.userIdEnd || '',
    Domain: school?.Domain || [],
    Abbreviation: school?.Abbreviation || '',
    isNumberLogin: school?.isNumberLogin || 'false'
  };

  const formik = useFormik({
    onSubmit: async (values): Promise<void> => {
      await dispatch(
        !!school
          ? updateSchoolItem({
              schoolId: school.id,
              values: {
                ...values,
                Name: values.Name.trim(),
                Abbreviation: values.Abbreviation.trim(),
                isNumberLogin: `${values.isNumberLogin}`,
                Domain:
                  typeof values.Domain === 'string'
                    ? (values.Domain as string)
                        .split(/ *, */)
                        .map((item) => item.trim())
                        .filter(Boolean)
                    : values.Domain.map((item) => item.trim()).filter(Boolean)
              }
            })
          : addNewSchool({
              values: {
                ...values,
                Name: values.Name.trim(),
                Abbreviation: values.Abbreviation.trim(),
                Domain:
                  typeof values.Domain === 'string'
                    ? (values.Domain as string)
                        .split(/ *, */)
                        .map((item) => item.trim())
                        .filter(Boolean)
                    : values.Domain.map((item) => item.trim()).filter(Boolean)
              }
            })
      );
      dispatch(closeModal());
    },
    initialValues,
    validationSchema: validation.ADD_SCHOOL,
    enableReinitialize: true
  });

  return (
    <Modal
      open={isModalOpened}
      title={!!school ? 'Edit School' : 'Add New School'}
      onCancel={handleCancel}
      footer={[
        <Button
          key='save'
          type={'primary'}
          onClick={(): void => formik.handleSubmit()}
          disabled={isModalDataLoading}
        >
          Save
        </Button>
      ]}
    >
      <form onSubmit={formik.handleSubmit}>
        {FIELDS.map((field): ReactNode => {
          const value = getIn(formik.values, field.fieldName);

          if (field.fieldName === 'isNumberLogin' && !school) {
            return null;
          }

          return (
            <Styles.InputContainer
              key={field.fieldName}
              isCheckobox={field.fieldName === 'isNumberLogin'}
            >
              {field.fieldName === 'isNumberLogin' ? (
                <Checkbox
                  name={field.fieldName}
                  checked={typeof value === 'string' ? value === 'true' : value}
                  onChange={formik.handleChange}
                  disabled={isModalDataLoading}
                >
                  {field.placeholder}
                </Checkbox>
              ) : (
                <Input
                  name={field.fieldName}
                  value={typeof value === 'string' ? value : value.join(', ')}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  placeholder={field.placeholder}
                  disabled={isModalDataLoading}
                  allowClear
                />
              )}
              {getFieldError(formik, field.fieldName)}
            </Styles.InputContainer>
          );
        })}
      </form>
    </Modal>
  );
};

export default SchoolActionFormModal;
