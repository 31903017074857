import './App.css';
import 'antd/dist/reset.css';
import { store } from 'services/store';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { MainRoutes } from './routes';

function App(): JSX.Element {
  return (
    <Provider store={store}>
      <BrowserRouter future={{ v7_relativeSplatPath: true, v7_startTransition: true }}>
        <MainRoutes />
      </BrowserRouter>
    </Provider>
  );
}

export default App;
