import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from 'shared/hooks';

type NotGuardedRouteProps = {
  path: string;
  element: React.ReactElement;
};

const NotGuardedRoute: React.FC<NotGuardedRouteProps> = ({ element }) => {
  const isAuth = useAppSelector(state => state.auth.isAuth);

  return isAuth ? <Navigate to="/sessions" replace /> : element;
};

export default NotGuardedRoute;

