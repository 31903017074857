import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAppSelector } from 'shared/hooks';
import { AuthState, EAdminRoles } from 'shared/types';

type GuardedRouteProps = {
  element: React.ReactElement;
  allowedRoles: EAdminRoles[];
  path: string;
};

const GuardedRoute: React.FC<GuardedRouteProps> = ({ allowedRoles, element }) => {
  const { isAuth, user } = useAppSelector((state): AuthState => state.auth);
  const location = useLocation();

  if (!isAuth) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  if (!allowedRoles.some(role => role === user?.role)) {
    return <Navigate to="/not-found" replace />;
  }

  return element;
};


export default GuardedRoute;
