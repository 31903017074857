import { DEVICE } from 'shared/constants/deviceSizes';
import styled from 'styled-components';

export const RootContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== 'isAuth',
})<{ isAuth: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  padding-left: ${({ isAuth }) => (isAuth ? '256px' : '0')};
  
  @media ${DEVICE.tabletLarge} {
    padding-left: 0;
  }
`;

export const MenuButton = styled.button`
  width: 30px;
  position: absolute;
  top: 20px;
  left: 15px;
  display: none;

  @media ${DEVICE.tabletLarge} {
    display: block;
  }
`;
