import { useCallback, useState } from 'react';
import {
  EResponseHeaders,
  ESnackbarStyle,
  Session,
  ReportFormValues,
  VrUserInternal
} from 'shared/types';
import { PAGE_SIZE } from 'shared/constants/pagination';
import { getSessions, downloadAllSessionProgressReports } from 'services/api/sessionsService';
import { useDebounce, useSchoolParams } from 'shared/hooks';
import { openNotification } from 'utils/notification-utils';
import { getSortQueryParams } from 'utils/sort-utils';
import { searchVrUsers } from 'services/api/vrUsersService';

type ReportResult = {
  sessions: Session[];
  fetchSessions: () => Promise<void>;
  vrUsers: VrUserInternal[];
  fetchVrUsers: () => Promise<void>;
  totalPages: number;
  totalSessions: number;
  isSessionsLoading: boolean;
  setCurrentPage: (page: number) => void;
  currentPage: number;
  setSortBy: (value: string) => void;
  setFormValues: (params: ReportFormValues) => void;
  formValues: ReportFormValues;
  clearValues: () => void;
  avgDuration: number;
  downloadSessionProgressReports: () => Promise<void>;
};

const useSessionsReportData = (): ReportResult => {
  const INITIAL_VALUES = {
    startedAtStart: '',
    startedAtEnd: '',
    roomId: '',
    userId: '',
    userIds: undefined,
    schoolId: '',
    minDuration: '',
    maxDuration: '',
    minUsers: '',
    finishedAtEnd: '',
    finishedAtStart: '',
    internalId: ''
  };
  const [sessions, setSessions] = useState<Session[]>([]);
  const [vrUsers, setVrUsers] = useState<VrUserInternal[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [avgDuration, setAvgDuration] = useState<number>(0);
  const [totalSessions, setTotalSessions] = useState<number>(0);
  const [sortBy, setSortBy] = useState<string>('');
  const [isSessionsLoading, setIsSessionsLoading] = useState<boolean>(true);
  const { getSchoolParams } = useSchoolParams();
  const [formValues, setFormValues] = useState<ReportFormValues>(INITIAL_VALUES);

  const debouncedParams = useDebounce(formValues, 1000);
  const debouncedSchool = useDebounce(formValues.schoolId, 1000);

  const fetchSessions = useCallback(async (): Promise<void> => {
    setIsSessionsLoading(true);
    try {
      const orderBy = { orderBy: sortBy || 'startedAt,DESC' };
      const response = await getSessions(currentPage, PAGE_SIZE, orderBy, {
        ...getSortQueryParams(debouncedParams),
        ...getSchoolParams(),
        addAvg: true
      });
      setSessions(response.data);
      setAvgDuration(response.headers[EResponseHeaders.AVG_DURATION] || 0);
      setTotalPages(+response.headers[EResponseHeaders.TOTAL_PAGES]);
      setTotalSessions(+response.headers[EResponseHeaders.TOTAL_RECORDS]);
      if (+response.headers[EResponseHeaders.TOTAL_RECORDS] <= PAGE_SIZE) setCurrentPage(1);
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e.message);
    } finally {
      setIsSessionsLoading(false);
    }
  }, [currentPage, sortBy, debouncedParams, getSchoolParams]);

  const fetchVrUsers = useCallback(async (): Promise<void> => {
    setIsSessionsLoading(true);
    try {
      const response = await searchVrUsers('', {
        ...getSortQueryParams(debouncedParams),
        ...getSchoolParams()
      });
      setVrUsers(response.data);
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e.message);
    } finally {
      setIsSessionsLoading(false);
    }
    // disable exhaustive-deps rule because we don't want to trigger this effect when debouncedParams change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSchool]);

  const clearValues = (): void => setFormValues(INITIAL_VALUES);

  const downloadSessionProgressReports = async (): Promise<void> => {
    try {
      await downloadAllSessionProgressReports({
        ...getSortQueryParams(debouncedParams),
        ...getSchoolParams()
      });
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e.message || e);
    }
  };

  return {
    sessions,
    vrUsers,
    fetchSessions,
    fetchVrUsers,
    totalPages,
    totalSessions,
    isSessionsLoading,
    setCurrentPage,
    currentPage,
    setSortBy,
    setFormValues,
    formValues,
    clearValues,
    avgDuration,
    downloadSessionProgressReports
  };
};

export default useSessionsReportData;
