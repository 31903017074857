import React from 'react';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { DatePeriod } from 'shared/types';

type Props = {
  values: DatePeriod;
  setDates: (dates: DatePeriod) => void;
  isBlock?: boolean;
};

const DateRange: React.FC<Props> = ({ values, setDates, isBlock }): JSX.Element => {
  const { RangePicker } = DatePicker;
  const { startedAtStart, startedAtEnd } = values;
  const START = startedAtStart ? dayjs(startedAtStart) : null;
  const END = startedAtEnd ? dayjs(startedAtEnd).subtract(1, 'd') : null;

  const handleDateChange = (dates: (dayjs.Dayjs | null )[] | null): void => {
    if (!dates || dates.length === 0 || (dates[0] === null && dates[1] === null)) {
      setDates({ startedAtStart: '', startedAtEnd: '' });
      return;
    }

    const startedAtStartNew = dates[0] ? dates[0].add(2, 'hour').toISOString() : '';
    const startedAtEndNew = dates[1] ? dates[1].add(1, 'day').add(2, 'hour').toISOString() : '';

    setDates({ startedAtStart: startedAtStartNew, startedAtEnd: startedAtEndNew });
  };

  return (
    <>
      <RangePicker
        onChange={handleDateChange}
        value={[START, END]}
        presets={[
          { label: 'Today', value: [dayjs(), dayjs()] },
          { label: 'This Month', value: [dayjs().startOf('month'), dayjs().endOf('month')] }
        ]}
        allowEmpty={[true, true]}
        allowClear={true}
        inputReadOnly={true}
        size='small'
        style={{ width: isBlock ? '180px' : '270px', display: isBlock ? 'block' : '' }}
      />
    </>
  );
};

export default DateRange;
