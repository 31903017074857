import { createAction, createReducer } from '@reduxjs/toolkit';
import { Admin, AuthState } from 'shared/types';

// Load authentication state from localStorage
const storedAuth = JSON.parse(localStorage.getItem('authState') || '{}');

const initialState: AuthState = {
  isAuth: storedAuth?.isAuth || false,
  user: storedAuth?.user || null,
};

// Actions
export const loginUserSuccess = createAction<Admin | null>('auth/loginUserSuccess');
export const loginUserFail = createAction('auth/loginUserFail');
export const logoutUser = createAction('auth/logout');
export const updateUser = createAction<Admin>('auth/updateUser');

// Reducer
export const authReducer = createReducer(initialState, (builder): void => {
  builder
    .addCase(loginUserSuccess, (state, action): void => {
      state.isAuth = true;
      state.user = action.payload;
      localStorage.setItem('authState', JSON.stringify(state));
    })
    .addCase(loginUserFail, (state): void => {
      state.isAuth = false;
      state.user = null;
    })
    .addCase(logoutUser, (state): void => {
      localStorage.removeItem('authState');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      state.isAuth = false;
      state.user = null;
    })
    .addCase(updateUser, (state, action): void => {
      state.user = action.payload;
    });
});

